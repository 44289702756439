import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap CSS if needed only in this component
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Hero() {
    return (
        <Container className="py-5 text-center">

            <div className='pb-3'>
                <h1>DPNK pro VÚMOP</h1>
                <p>Podpultový neoficiální náhled na výsledky pro jednotivce VÚMOPu.</p>
            </div>
            <Row className='justify-content-center'>
                <div className='col-12 col-md-4 mb-2 mb-md-0'>
                    <a href="https://dpnk.dopracenakole.cz/jizdy" target="_blank" rel="noopener noreferrer" className="btn btn-danger rounded-0 w-100">Zápis jízdy</a>
                </div>
                <div className='col-12 col-md-4'>
                    <a href="https://dpnk.dopracenakole.cz/souteze_vykonnostni/" target="_blank" rel="noopener noreferrer" className="btn rounded-0 btn-outline-info w-100">Oficiální výsledky DPNK</a>
                </div>
            </Row>
        </Container>
    );
}

export default Hero;