import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { Col, Row } from 'react-bootstrap';

function cleanText(text) {
    return text.replace(/&nbsp;/gi, ' ').replace(/\s+-\s+/g, '-');
}

function MainContent() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [apiSource, setApiSource] = useState('celorepublikova');

    const fetchData = async (source) => {
        try {
            // URL pro tvůj lokální proxy server
            const baseUrl = process.env.REACT_APP_API_BASE_URL;

            const apiUrlCr = '/vysledky_souteze_json/celorepublikova-vykonnost-2024-pouze-informativni/?search[value]=Výzkumný+ústav+meliorací+a+ochrany+půdy&length=50';
            const apiUrlPraha = '/vysledky_souteze_json/zelene-kilometry-2024-Praha/?search[value]=Výzkumný+ústav+meliorací+a+ochrany+půdy&length=50';

            const fullPath = source === 'celorepublikova' ? apiUrlCr : apiUrlPraha;

            const url = `${baseUrl}${fullPath}`;

            const response = await axios.get(url);

            let rawData = response.data.data;
            rawData = rawData.map(row => {
                return [
                    row[1],             // Ujeté km
                    row[2],             // Jméno
                    row[3],             // Tým
                    row[5],             // Role
                    row[6],             // Pohlaví
                    cleanText(row[0]),  // Pořadí
                ];
            });

            // Přidat pořadí VÚMOP
            const sortedByKm = [...rawData].sort((a, b) => parseFloat(b[1]) - parseFloat(a[1]));
            const dataWithRank = sortedByKm.map((item, index) => [index + 1, ...item]);

            setData(dataWithRank);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data: ', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(apiSource);
    }, [apiSource]);

    const handleApiSourceChange = (event) => {
        setLoading(true);
        setApiSource(event.target.value);
    };

    const labelPoradi = apiSource === 'celorepublikova' ? 'Pořadí v ČR' : 'Pořadí v Praze';

    return (
        <Container>
            <div>
                <Container className='pb-3 g-0'>
                    <Row className="justify-content-md-center">
                        <Col xs lg="8">
                            <Card>
                                <Card.Header as="h5">Zdroj dat</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <section>
                                            <i style={{ fontSize: 'smaller' }} className="text-muted">
                                                DPNK nabízí dva zdroje dat, jejich výsledky se mohou lišit.
                                            </i>
                                            <div className='lead'>
                                                Přepínej mezi dvěma dostupnými zdroji dat a sleduj své výkony lokálně i celorepublikově! 🚴‍♂️👟
                                            </div>

                                            <div>
                                                <Form>
                                                    <div key={`default-radio`} className="mx-4 mt-3">
                                                        <Form.Check
                                                            type="radio"
                                                            id="radio-celorepublikova"
                                                            label="Celorepubliková výkonnost"
                                                            value="celorepublikova"
                                                            checked={apiSource === 'celorepublikova'}
                                                            onChange={handleApiSourceChange}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            label="Zelené kilometry Praha"
                                                            id="radio-praha"
                                                            value="praha"
                                                            checked={apiSource === 'praha'}
                                                            onChange={handleApiSourceChange}
                                                        />
                                                    </div>
                                                </Form>
                                            </div>
                                        </section>
                                        
                                    </Card.Text>
                                    </Card.Body>
                                    <Card.Header as="h5" className='border-top'>Výsledky dle ujeté vzdálenosti</Card.Header>
                                    <Card.Body>
                                    <Card.Text>

                                        <section>
                                            {loading ? (
                                                <div className='my-5 text-center'>
                                                    <h2>Načítám data...</h2>
                                                    <Spinner animation="border" />
                                                </div>
                                            ) : (
                                                <Table striped bordered hover size="sm" responsive="md">
                                                    <thead>
                                                        <tr>
                                                            <th>Pořadí VÚMOP</th>
                                                            <th>Ujeté km</th>
                                                            <th>Jméno</th>
                                                            <th>Tým</th>
                                                            <th>Role</th>
                                                            <th>Pohlaví</th>
                                                            <th>{labelPoradi}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((item, index) => (
                                                            <tr key={index}>
                                                                {item.map((cell, cellIndex) => <td key={cellIndex}>{cell}</td>)}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            )}
                                        </section>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Container>
    );
}

export default MainContent;
