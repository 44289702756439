import React from 'react';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
    return (
        <footer className="text-center mt-5 pt-3">
            <Container fluid className='g-0'>
                <Container>
                    <div className='pb-3'>
                        <img src={process.env.PUBLIC_URL + '/dpnkvumop.png'} alt="DPNK pro VÚMOP" width="150" />
                    </div>
                    <p className='lead'>Uvidíme se na cestě a také na tradičním vyhlášení.</p>
                    <p><strong>17. - 18. června v Kempu U Bořka</strong></p>
                    <p>Adresa: Pod Duby, Poddubí, Kaliště-Čerčany.</p>
                    <p><a href="https://mapy.cz/s/reguhaceca">Zobrazit na Mapy.cz</a></p>
                </Container>

                <div>
                    <iframe
                        style={{ border: "none", width: "100%", height: "350px" }}
                        src="https://frame.mapy.cz/s/golopafabe"
                        frameborder="0"
                    ></iframe>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;